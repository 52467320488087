import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  width: 90%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  width: 100%;
  height: 7rem;
  display: flex;
  align-items: center;
  padding: 2rem 0;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.25);

  header {
    font-size: 2.2rem;
    margin-left: 1.6rem;
    font-weight: 700;
  }
`;

export const HeaderButtonBack = styled.button`
  background: none;
  border: 0;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color-text-in-primary);
  font-family: 'Roboto', sans-serif;
  outline: none;
`;

export const ProductsBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 1.5rem;
  padding-bottom: 1.5rem;
`;
