import React, { useMemo, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

import { useProducts } from '../../hooks/productHook';

import {
  Container,
  Content,
  Header,
  HeaderButtonBack,
  ProductsBox,
} from './styles';

import Product from '../../components/Product';

interface IRouteMatchParams {
  category_id: string;
  category_name: string;
}

const Products: React.FC = () => {
  const { params } = useRouteMatch<IRouteMatchParams>();
  const { category_id, category_name } = params;
  const { goBack } = useHistory();

  const { products } = useProducts();

  const categoryIdConvertStringToNumber = useMemo(() => {
    return Number(category_id);
  }, [category_id]);

  useEffect(() => {
    if (products.length <= 0) {
      goBack();
    }
  }, [products.length, goBack]);

  return (
    <Container>
      <Content>
        <Header>
          <HeaderButtonBack
            type="button"
            onClick={() => {
              goBack();
            }}
          >
            <FiArrowLeft />
            <header>{category_name.toUpperCase()}</header>
          </HeaderButtonBack>
        </Header>

        <ProductsBox>
          {products &&
            products
              .filter(
                product =>
                  product.category_id === categoryIdConvertStringToNumber,
              )
              .map(product => <Product key={product.id} product={product} />)}
        </ProductsBox>
      </Content>
    </Container>
  );
};

export default Products;
