import styled from 'styled-components';

export const Container = styled.div`
  background-color: var(--color-secundary);
  border-radius: 0.5rem;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.25);

  & + div {
    margin-top: 1.6rem;
  }
`;

export const ProductContent = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ProductImage = styled.img`
  width: 10rem;
  height: 10rem;

  border-radius: 15px;
  padding: 10px;
`;

export const ProductItemContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

export const ProductItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 0 0;

  p {
    padding: 1.6rem 1.6rem 1.6rem 1.6rem;
    font-size: 2rem;

    flex-direction: row;
    flex: 1;



    margin-right: 1rem;

    /* & + p {
      color: var(--color-text-in-primary);
      margin-left: 2rem;
      text-align: right;
    } */
  }

  p.price {
    flex: 0;

    text-align: right;
  }
`;

export const PriceBox = styled.div`
  display: flex;
  flex-direction: row;

  /* p {
    color: var(--color-text-in-primary);
    margin-left: 1rem;
    text-align: right;
  } */

  p.promotion {
    text-decoration: line-through;
  }
`;

export const ProductDescription = styled.div`
  width: 90%;

  font-family: 'Roboto', sans-serif;
  font-size: 1.6rem;
  text-align: justify;

  padding: 0 1.6rem 1rem 1.6rem;
  margin-top: -1rem;
`;

export const ExtraProductButton = styled.button`
  margin: 0 0 1rem 1.6rem;
  font-family: 'Roboto', sans-serif;
  background: var(--color-background-extra-products);
  color: var(--color-button-text);
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border: 0;
  border-radius: 0.5rem;
  font-size: 2rem;
`;

export const PizzaFlavorButton = styled.button`
  margin: 0 0 1rem 1.6rem;
  font-family: 'Roboto', sans-serif;
  background: var(--color-background-extra-products);
  color: var(--color-button-text);
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border: 0;
  border-radius: 0.5rem;
  font-size: 2rem;
`;
