import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  width: 90vw;
  height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 2rem;
    margin: 0 1.5rem;
  }
`;

export const QRCodeBox = styled.div`
  width: 30rem;
  height: 25rem;
  margin-top: 2.2rem;

  button {
    width: 20rem;
    height: 10rem;
    background: #fff;
  }
`;
