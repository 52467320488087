import React, { createContext, useCallback, useState, useContext } from 'react';

interface IExtraProduct {
  id: number;
  name: string;
  price: string;
}

interface IPizzaFlavor {
  id: number;
  name: string;
  price: string;
  description: string;
}

interface Product {
  id: number;
  name: string;
  price: string;
  category_id: number;
  description: string;
  is_promotional: boolean;
  promotional_price: string;
  image: string;
  image_url: string;
  extra_products: Array<IExtraProduct>;
  pizza_flavors: Array<IPizzaFlavor>;
}

interface ProductContextData {
  products: Array<Product>;
  productsMenu(product: Array<Product>): void;
}

const ProductsContext = createContext<ProductContextData>(
  {} as ProductContextData,
);

const ProductProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<Product[]>([]);

  const productsMenu = useCallback((products: Array<Product>) => {
    setData(products);
  }, []);

  return (
    <ProductsContext.Provider value={{ products: data, productsMenu }}>
      {children}
    </ProductsContext.Provider>
  );
};

function useProducts(): ProductContextData {
  const context = useContext(ProductsContext);

  return context;
}

export { ProductProvider, useProducts };
