import React, { createContext, useCallback, useState, useContext } from 'react';

interface ExtraProducts {
  name: string;
  price: string;
  product_id: number;
}

interface ExtraProductsContextData {
  extraProducts: Array<ExtraProducts>;
  extraProductsMenu(product: Array<ExtraProducts>): void;
}

const ExtraProductsContext = createContext<ExtraProductsContextData>(
  {} as ExtraProductsContextData,
);

const ExtraProductProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<ExtraProducts[]>([]);

  const extraProductsMenu = useCallback(
    (extraProducts: Array<ExtraProducts>) => {
      setData(extraProducts);
    },
    [],
  );

  return (
    <ExtraProductsContext.Provider
      value={{ extraProducts: data, extraProductsMenu }}
    >
      {children}
    </ExtraProductsContext.Provider>
  );
};

function useExtraProducts(): ExtraProductsContextData {
  const context = useContext(ExtraProductsContext);

  return context;
}

export { ExtraProductProvider, useExtraProducts };
