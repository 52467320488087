import React from 'react';

import { CategoryItem } from './styles';

interface ICategoryProps {
  title: string;
}

const Category: React.FC<ICategoryProps> = ({ title }) => {
  return (
    <CategoryItem>
      <p>{title}</p>
    </CategoryItem>
  );
};

export default Category;
