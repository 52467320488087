import React, { useEffect } from 'react';
import { useRouteMatch, Link, useHistory } from 'react-router-dom';

import Category from '../../components/Category';

import { useCustomer } from '../../hooks/customerHook';
import { useCategories } from '../../hooks/categoryHook';
import { useProducts } from '../../hooks/productHook';

import api from '../../service/api';

import { Container, Content, Header, CategoriesBox } from './styles';

interface IRouteMatchParams {
  customer_url: string;
}

const Menu: React.FC = () => {
  const { params } = useRouteMatch<IRouteMatchParams>();
  const { customer_url } = params;
  const { push } = useHistory();

  const customerHook = useCustomer();
  const categoriesHook = useCategories();
  const productsHook = useProducts();
  // const [menu, setMenu] = useState<IMenu>({} as IMenu);

  useEffect(() => {
    async function loadCategories() {
      try {
        const result = await api.get(`menu/${customer_url}`);

        // setMenu(result.data);
        // setCustomerName(result.data.customer_name);
        customerHook.setCustomer(result.data.customer_name);
        categoriesHook.categoriesMenu(result.data.categories);
        productsHook.productsMenu(result.data.products);
      } catch (err) {
        push('/');
      }
    }

    if (categoriesHook.categories.length <= 0) {
      loadCategories();
    }
  }, [customer_url, customerHook, categoriesHook, productsHook, push]);

  return (
    <Container>
      <Content>
        <Header>
          <header>{customerHook.name}</header>
        </Header>

        <CategoriesBox>
          {categoriesHook.categories &&
            categoriesHook.categories.map(category => (
              <Link
                key={category.id}
                to={`/products/${category.name}/${category.id}`}
              >
                <Category title={category.name.toUpperCase()} />
              </Link>
            ))}
        </CategoriesBox>
      </Content>
    </Container>
  );
};

export default Menu;
