import React, { createContext, useCallback, useState, useContext } from 'react';

interface CustomerContextData {
  name: string;
  setCustomer(name: string): void;
}

const CustomerContext = createContext<CustomerContextData>(
  {} as CustomerContextData,
);

const CustomerProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<string>('');

  const setCustomer = useCallback((name: string) => {
    setData(name);
  }, []);

  return (
    <CustomerContext.Provider value={{ name: data, setCustomer }}>
      {children}
    </CustomerContext.Provider>
  );
};

function useCustomer(): CustomerContextData {
  const context = useContext(CustomerContext);

  return context;
}

export { CustomerProvider, useCustomer };
