import styled from 'styled-components';

export const CategoryItem = styled.div`
  background-color: var(--color-secundary);
  height: 7rem;
  display: flex;
  align-items: center;
  margin: 0 0 1.6rem 0;
  border-radius: 0.5rem;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.25);

  p {
    padding: 0 1.6rem;
    font-size: 2rem;
  }
`;
