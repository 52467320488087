import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    :root {
        font-size: 60%;

        --color-background: #e9e9e9;
        --color-secundary: #FFFFFF;
        --color-secundary-dark: #04BF58;
        --color-title-in-primary: #515151;
        --color-text-in-primary: #515151;
        --color-text-extra-products: #f04141;
        --color-line-in-white: #E6E6F0;
        --color-button-text: #FFFFFF;
        --color-background-extra-products: #ffa500;

    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: 0;
    }

    body {
        background: var(--color-line-in-white);
        color: var(--color-title-in-primary);
        -webkit-font-smoothing: antialiased;
    }

    body, input, button {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
    }

    h1, h2, h3, h4, h5, h6, strong {
        font-weight: 500;
    }

    a {
      text-decoration: none;
      color: var(--color-title-in-primary);
    }

    button {
        cursor: pointer;
    }

`;
