import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ScanQRCode from '../pages/ScanQRCode';
import Menu from '../pages/Menu';
import Products from '../pages/Products';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={ScanQRCode} />
    <Route path="/menu/:customer_url" component={Menu} />
    {/* <Route exact path="/:customer_url" component={Menu} /> */}
    <Route path="/products/:category_name/:category_id+" component={Products} />
  </Switch>
);

export default Routes;
