import React from 'react';

import { CustomerProvider } from './customerHook';
import { CategoryProvider } from './categoryHook';
import { ProductProvider } from './productHook';
import { ExtraProductProvider } from './extraProductsHook';

const AppProvider: React.FC = ({ children }) => (
  <CustomerProvider>
    <CategoryProvider>
      <ProductProvider>
        <ExtraProductProvider>{children}</ExtraProductProvider>
      </ProductProvider>
    </CategoryProvider>
  </CustomerProvider>
);

export default AppProvider;
