import React, { useState, useCallback } from 'react';
import Zoom from 'react-medium-image-zoom';

import ModaExtraProducts from '../ModalExtraProducts';
import ModalPizzaFlavors from '../ModalPizzaFlavors';

import 'react-medium-image-zoom/dist/styles.css';

import {
  Container,
  ProductContent,
  ProductImage,
  ProductItemContent,
  ProductItem,
  PriceBox,
  ProductDescription,
  ExtraProductButton,
  PizzaFlavorButton,
} from './styles';

interface IExtraProduct {
  id: number;
  name: string;
  price: string;
}

interface IPizzaFlavor {
  id: number;
  name: string;
  price: string;
  description: string;
}

interface IProduct {
  id: number;
  name: string;
  price: string;
  description: string;
  is_promotional: boolean;
  promotional_price: string;
  image_url: string;
  extra_products: Array<IExtraProduct>;
  pizza_flavors: Array<IPizzaFlavor>;
}

interface IProductProps {
  product: IProduct;
}

const Product: React.FC<IProductProps> = ({ product }) => {
  const {
    name,
    price,
    description,
    is_promotional,
    promotional_price,
    image_url,
    extra_products,
    pizza_flavors,
  } = product;


  const [extraProductModalOpen, setExtraProductModalOpen] = useState(false);
  const [pizzaFlavorModalOpen, setPizzaFlavorModalOpen] = useState(false);

  const handleToggleExtraProductModal = useCallback(() => {
    setExtraProductModalOpen(!extraProductModalOpen);
  }, [extraProductModalOpen]);

  const handleTogglePizzaFlavorModal = useCallback(() => {
    setPizzaFlavorModalOpen(!pizzaFlavorModalOpen);
  }, [pizzaFlavorModalOpen]);

  return (
    <>
      <ModaExtraProducts
        isOpen={extraProductModalOpen}
        setIsOpen={handleToggleExtraProductModal}
        product_name={name}
        extraProdutos={extra_products}
      />

      <ModalPizzaFlavors
        isOpen={pizzaFlavorModalOpen}
        setIsOpen={handleTogglePizzaFlavorModal}
        product_name={name}
        pizzaFlavors={pizza_flavors}
      />
      <Container>
        <ProductContent>
          {image_url && (
            <Zoom
              wrapStyle={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ProductImage
                // src={`https://cardapiodigitals3.s3.amazonaws.com/${image}`}
                src={image_url}
              />
            </Zoom>
          )}

          <ProductItemContent>
            <ProductItem>
              <p>{name}</p>

              {is_promotional ? (
                <PriceBox>
                  <p className={is_promotional ? 'promotion' : ''}>{price}</p>

                  {is_promotional && <p>{promotional_price}</p>}
                </PriceBox>
              ) : (
                pizza_flavors.length === 0 && <p className='price'>{price}</p>
              )}
            </ProductItem>

            {description && (
              <ProductDescription>
                <p>{description}</p>
              </ProductDescription>
            )}
          </ProductItemContent>
        </ProductContent>

        {pizza_flavors.length > 0 && (
          <PizzaFlavorButton onClick={handleTogglePizzaFlavorModal}>
            Sabores
          </PizzaFlavorButton>
        )}

        {extra_products.length > 0 && (
          <ExtraProductButton onClick={handleToggleExtraProductModal}>
            Complementos
          </ExtraProductButton>
        )}
      </Container>
    </>
  );
};

export default Product;
