import React from 'react';

import { Container, Content } from './styles';

const ScanQRCode: React.FC = () => {
  return (
    <Container>
      <Content>
        <h1>
          Utilize sua câmera ou um aplicativo para fazer a leitura do QRCode
        </h1>
      </Content>
    </Container>
  );
};

export default ScanQRCode;
