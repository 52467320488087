import React from 'react';
import { FiXCircle } from 'react-icons/fi';

import Modal from '../Modal';

import { Header, Container, Content, PizzaFlavor, PizzaFlavorNamePrice, PizzaFlavorDescription } from './styles';

interface IPizzaFlavor {
  id: number;
  name: string;
  price: string;
  description: string;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  product_name: string;
  pizzaFlavors: Array<IPizzaFlavor>;
}

const ModalPizzaFlavors: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  product_name,
  pizzaFlavors,
}) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Header>
        <p>{product_name}</p>

        <button
          type="button"
          onClick={() => {
            setIsOpen();
          }}
        >
          <FiXCircle />
        </button>
      </Header>

      <Container>
        <Content>
          {pizzaFlavors.map(flavor => (
            <PizzaFlavor key={flavor.name}>
              <PizzaFlavorNamePrice>
                <p>{flavor.name}</p>
                <p>{flavor.price}</p>
              </PizzaFlavorNamePrice>

              <PizzaFlavorDescription>{flavor.description}</PizzaFlavorDescription>
            </PizzaFlavor>
          ))}
        </Content>
      </Container>
    </Modal>
  );
};

export default ModalPizzaFlavors;
