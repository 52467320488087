import React, { createContext, useCallback, useState, useContext } from 'react';

interface Category {
  id: number;
  name: string;
}

interface CategoryContextData {
  categories: Array<Category>;
  categoriesMenu(category: Array<Category>): void;
}

const CategoryContext = createContext<CategoryContextData>(
  {} as CategoryContextData,
);

const CategoryProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<Category[]>([]);

  const categoriesMenu = useCallback((categories: Array<Category>) => {
    setData(categories);
  }, []);

  return (
    <CategoryContext.Provider value={{ categories: data, categoriesMenu }}>
      {children}
    </CategoryContext.Provider>
  );
};

function useCategories(): CategoryContextData {
  const context = useContext(CategoryContext);

  return context;
}

export { CategoryProvider, useCategories };
