import styled from 'styled-components';

export const Header = styled.div`
  position: absolute;

  width: 87%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    display: flex;
    flex: 1;
    margin-right: 1rem;
  }

  button {
    background: none;
    border: 0;
    font-size: 1.6rem;
    color: var(--color-text-extra-products);

    svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
`;

export const Container = styled.div`
  height: 100%;

  position: relative;

  top: 5rem;

  /* margin-top: 1.6rem; */
`;

export const Content = styled.div``;

export const ExtraProduct = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & + div {
    margin-top: 1rem;
  }

  p {
    width: 90%;

    & + p {
      text-align: right;
      width: 17%;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }
`;
