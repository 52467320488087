import React from 'react';
import { FiXCircle } from 'react-icons/fi';

import Modal from '../Modal';

import { Header, Container, Content, ExtraProduct } from './styles';

interface IExtraProduct {
  id: number;
  name: string;
  price: string;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  product_name: string;
  extraProdutos: Array<IExtraProduct>;
}

const ModalExtraProducts: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  product_name,
  extraProdutos,
}) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Header>
        <p>{product_name}</p>

        <button
          type="button"
          onClick={() => {
            setIsOpen();
          }}
        >
          <FiXCircle />
        </button>
      </Header>

      <Container>
        <Content>
          {extraProdutos.map(extra => (
            <ExtraProduct key={extra.name}>
              <p>{extra.name}</p>
              <p>{extra.price}</p>
            </ExtraProduct>
          ))}
        </Content>
      </Container>
    </Modal>
  );
};

export default ModalExtraProducts;
